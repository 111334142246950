import React from 'react'

import { Arrow, Plus } from './icons'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const FormModal = () => {

  const [firstname, setFirstname] = React.useState('')
  const [lastname, setLastname] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [company, setCompany] = React.useState('')
  const [complete, setComplete] = React.useState(false)

  const handleSubmit = async e => {
    e.preventDefault()
    setComplete(true)
    const data = encode({ firstname, lastname, email, company, type: 'Demo Request' })
    await fetch("https://hooks.zapier.com/hooks/catch/2181815/brir4y8/?"+data)
  }

  return (
    <div className='form-modal'>
      <button type='button' className='form-modal__close' onClick={() => {
        document.querySelector('.form-modal').classList.toggle('active')
      }}><Plus fill={'white'} /></button>
      <h3>Request Demo</h3>
      { complete ?
        <p>Thank you for your interest. We'll be in touch shortly.</p> :
        <form onSubmit={handleSubmit}>
          <div className='form-field'>
            <input id='firstname' name='firstname' type='text' required onChange={e => setFirstname(e.target.value)} />
            <label htmlFor='firstname'>First name</label>
          </div>
          <div className='form-field'>
            <input id='lastname' name='lastname' type='text' required onChange={e => setLastname(e.target.value)} />
            <label htmlFor='lastname'>Last name</label>
          </div>
          <div className='form-field'>
            <input id='email' name='email' type='email' required onChange={e => setEmail(e.target.value)} />
            <label htmlFor='email'>Email address</label>
          </div>
          <div className='form-field'>
            <input id='company' name='company' type='text' required onChange={e => setCompany(e.target.value)} />
            <label htmlFor='company'>Company</label>
          </div>
          <div className='form-field'>
            <button type='submit'><span>{ 'Request demo' }</span><Arrow fill={'white'} /></button>
          </div>
        </form>
      }
    </div>
  )
}

export default FormModal
