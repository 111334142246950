import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import "../assets/scss/main.scss"

import Header from "../components/header"
import Footer from "../components/footer"
import FormModal from "../components/form-modal"
import CookiesPopup from "../components/cookies-popup"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      fragment WpSeo on WpPostTypeSEO {
        metaDesc
        metaKeywords
        metaRobotsNofollow
        opengraphAuthor
        opengraphDescription
        opengraphImage {
          localFile {
            publicURL
          }
        }
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        readingTime
        schema {
          articleType
          pageType
          raw
        }
        title
        twitterDescription
        twitterImage {
          localFile {
            publicURL
          }
        }
        twitterTitle
      }
      query {
        wp {
          siteOptions {
            options {
              facebook
              linkedin
              twitter
            }
          }
        }
        headerMenu: wpMenu(locations: { in: GATSBY_HEADER_MENU }) {
          menuItems {
            nodes {
              label
              path
              parentId
              childItems {
                nodes {
                  label
                  path
                  childItems {
                    nodes {
                      label
                      path
                    }
                  }
                }
              }
            }
          }
          locations
        }
        footerMenu: wpMenu(locations: { in: GATSBY_FOOTER_MENU }) {
          menuItems {
            nodes {
              label
              path
            }
          }
          locations
        }
      }
    `}
    render={(data) => {
      return (
        <>
          <Header menu={data.headerMenu} />
          <FormModal />
          <main>{children}</main>
          <Footer menu={data.footerMenu} social={data.wp.siteOptions.options} />
          <CookiesPopup />
        </>
      )
    }}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
