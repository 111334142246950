import React, { Component } from 'react'
import Link from '../utils/link'
import { LinkedIn, Twitter, Facebook } from './icons'
import Newsletter from './newsletter'

import Logo from './logo'
import HubspotForm from "react-hubspot-form"

class Footer extends Component {
  render() {
    const { social, menu } = this.props
    return (
      <footer className='footer'>
        <div className='footer__inner'>
          <ul className='footer__terms'>
            <li>© { new Date().getFullYear() }</li>
            { menu.menuItems.nodes.map((el, i) => (
              <li key={i}><Link to={el.path}>{ el.label }</Link></li>
            ))}
          </ul>
          <ul className='footer__address'>
            <li><Logo fill={'white'} /></li>
            <li>222 Exhibition Street, <br />Melbourne, VIC, Australia 3000</li>
            <li><Link to='mailto:contact@systema.ai'>contact@systema.ai</Link></li>
          </ul>
          <ul className='footer__social'>
            <li>Follow</li>
            { social.linkedin && <li><Link to={social.linkedin}><LinkedIn /> LinkedIn</Link></li> }
            { social.twitter && <li><Link to={social.twitter}><Twitter /> Twitter</Link></li> }
            { social.facebook && <li><Link to={social.facebook}><Facebook /> Facebook</Link></li> }
          </ul>
          <ul className='footer__newsletter'>
            <li>Get news from Systema AI</li>
            {/* <Newsletter /> */}
            <HubspotForm
              portalId="5357709"
              formId="fd47a800-4bcd-49f4-b96b-8e8aa1e79bd0"
              cssClass="newsletter"
              // onSubmit={() => console.log("Submit!")}
              // onReady={prepareForm}
              loading={<div>Loading...</div>}
              // submitButtonClass="btn--new"
            />
          </ul>
        </div>
      </footer>
    )
  }
}

export default Footer
