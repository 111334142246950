import React from 'react'

const Plus = ({fill = 'black', style = {}}) => (
  <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path d="M21.554 0H17.4463V17.3999H21.554V0Z" fill={fill}/>
    <path d="M39 17.4463H21.6001V21.554H39V17.4463Z" fill={fill}/>
    <path d="M17.4922 17.4463H0V21.554H17.4461V39.0001H21.5538V21.5078H17.4922V17.4463Z" fill={fill}/>
  </svg>
)

const Chevron = ({fill = 'black', style = {}}) => (
  <svg width="22" height="11" viewBox="0 0 22 11" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path d="M19.6633 0.00170368L10.5762 9.08887L12.0648 10.5775L21.152 1.49033L19.6633 0.00170368Z" fill={fill} />
    <path d="M1.48863 -0.000346787L0 1.48828L9.08716 10.5754L10.5758 9.08682L1.48863 -0.000346787Z" fill={fill} />
  </svg>
)

const Arrow = ({fill = 'black', style = {}}) => (
  <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path d="M20.1353 12.7402L18.676 11.2809L20.1353 9.82162L11.2319 0.918215L9.77263 2.37752L17.6758 10.2807L7.13277 10.2643L0.0330033 10.2807L0.0330038 12.3467L7.13277 12.3303L17.6266 12.3303L9.78903 20.1679L11.2483 21.6272L20.1353 12.7402Z" fill={fill} />
  </svg>
)

const TopArrow = ({fill = '#E5E5E5', style = {}}) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <circle cx="24" cy="24" r="24" fill={fill} />
    </g>
    <path d="M21.8897 38.7324L24.0786 36.5435L26.2676 38.7324L39.6227 25.3773L37.4337 23.1884L25.5789 35.0432L25.6035 19.2286L25.5789 8.57892L22.48 8.57892L22.5045 19.2286L22.5045 34.9694L10.7481 23.213L8.55917 25.4019L21.8897 38.7324Z" fill="white" />
  </svg>
)

const PlayIcon = ({fill = 'black', style = {}}) => (
  <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13 7.5L0.249999 14.8612L0.25 0.138783L13 7.5Z" fill={fill} />
  </svg>
)

const LinkedIn = ({fill = 'white', style = {}}) => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.1667 13.4583H12.0417C12.0417 13.4583 12.0417 10.5754 12.0417 9.49167C12.0417 7.10458 9.20833 7.28875 9.20833 9.49167C9.20833 10.6533 9.20833 13.4583 9.20833 13.4583H7.08333V5.66667H9.20833V6.92042C10.2 5.08583 14.1667 4.95125 14.1667 8.67708C14.1667 10.6958 14.1667 13.4583 14.1667 13.4583ZM4.60417 4.76708C3.91708 4.76708 3.36458 4.2075 3.36458 3.52042C3.36458 2.83333 3.91708 2.26667 4.60417 2.26667C5.29125 2.26667 5.84375 2.82625 5.84375 3.51333C5.84375 4.20042 5.29125 4.76708 4.60417 4.76708ZM5.66667 13.4583H3.54167V5.66667H5.66667V13.4583ZM13.4583 0H3.54167C1.58667 0 0 1.58667 0 3.54167V13.4583C0 15.4133 1.58667 17 3.54167 17H13.4583C15.4133 17 17 15.4133 17 13.4583V3.54167C17 1.58667 15.4133 0 13.4583 0Z" fill={fill}/>
  </svg>
)

const Facebook = ({fill = 'white', style = {}}) => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19 9.5C19 4.25329 14.7467 0 9.5 0C4.25329 0 0 4.25329 0 9.5C0 14.2417 3.47399 18.1719 8.01562 18.8846V12.2461H5.60352V9.5H8.01562V7.40703C8.01562 5.02609 9.43395 3.71094 11.6039 3.71094C12.6433 3.71094 13.7305 3.89648 13.7305 3.89648V6.23438H12.5326C11.3525 6.23438 10.9844 6.96673 10.9844 7.71801V9.5H13.6191L13.1979 12.2461H10.9844V18.8846C15.526 18.1719 19 14.2417 19 9.5Z" fill={fill} />
  </svg>
)

const Twitter = ({fill = 'white', style = {}}) => (
  <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.9517 4.0741C17.9638 4.25164 17.9638 4.42917 17.9638 4.60835C17.9638 10.0679 13.8363 16.3643 6.28885 16.3643V16.3611C4.05931 16.3635 1.87609 15.7205 0 14.5071C0.324193 14.5464 0.65001 14.566 0.97664 14.5669C2.82429 14.5685 4.61913 13.9442 6.07272 12.7948C4.31688 12.7612 2.77717 11.6084 2.23928 9.9255C2.85436 10.0449 3.48812 10.0204 4.09181 9.85432C2.17753 9.4657 0.800325 7.77131 0.800325 5.80448C0.800325 5.78648 0.800325 5.7693 0.800325 5.75212C1.37071 6.07202 2.00934 6.24956 2.6626 6.26919C0.858826 5.05751 0.303067 2.64234 1.39183 0.754049C3.47512 3.33531 6.54885 4.90452 9.84847 5.0706C9.51777 3.63557 9.96953 2.13181 11.0355 1.12303C12.6882 -0.442085 15.2874 -0.361089 16.841 1.30221C17.7599 1.11976 18.6407 0.780229 19.4467 0.299158C19.1404 1.25557 18.4993 2.068 17.6429 2.58425C18.4562 2.48771 19.2509 2.26844 20 1.93464C19.4483 2.76506 18.7552 3.48912 17.9517 4.0741Z" fill={fill} />
  </svg>
)

export { Plus, Chevron, Arrow, TopArrow, PlayIcon, LinkedIn, Twitter, Facebook }
