import React, { Component } from "react"

import { Plus } from "./icons"

class CookiesPopup extends Component {
  state = {
    active: false,
  }

  componentDidMount() {
    setTimeout(() => {
      if (localStorage.getItem("cookies_shown") === null) {
        this.setState({ active: true });
        localStorage.setItem("cookies_shown", true);
      }
    }, 2000)
  }

  render() {
    return (
      <section className={`cookies-popup ${this.state.active ? "active" : ""}`}>
        <div className="cookies-popup__inner">
          <button
            type="button"
            className="cookies-popup__close"
            onClick={() => this.setState({ active: false })}
          >
            <Plus fill={"white"} />
          </button>
          <h5 className="cookies-popup__heading">
            This website <strong>doesn't</strong> use cookies
          </h5>
          <p className="cookies-popup__paragraph">
            We don't use cookies to personalise content and ads, analyse our
            traffic or share information about your use of our site.
          </p>
          <p className="cookies-popup__paragraph">
            We're more interested in designing products to help your customers
            find what they're shopping for right now.
          </p>

          <a className="btn" href="/request-a-demo" target="_blank">Request a Demo</a>
        </div>
      </section>
    )
  }
}

export default CookiesPopup
