import React, { Component } from "react"
import Link from "../utils/link"

import Logo from "./logo"

import { TopArrow, Plus } from "./icons"

class Header extends Component {
  state = {
    offCanvas: false,
    scrolled: false,
    hidden: false,
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollDetect)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollDetect)
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  toggleModal = () => {
    document.querySelector(".form-modal").classList.toggle("active")
  }

  toggleScrolled = () => {
    this.setState({ scrolled: !this.state.scrolled })
  }

  lastScrollTop = 0
  onScroll = (e) => {
    let scrolled = window.scrollY > 75
    let st = window.pageYOffset || document.documentElement.scrollTop // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
    if (st > this.lastScrollTop) {
      // downscroll code
      !this.state.hidden && this.setState({ hidden: true })
    } else if (this.state.hidden) {
      this.setState({ hidden: false })
      // upscroll code
    }
    this.lastScrollTop = st <= 0 ? 0 : st // For Mobile or negative scrolling
    if (scrolled !== this.state.scrolled) {
      requestAnimationFrame(this.toggleScrolled)
    }
  }

  scrollDetect = () => {
    window.clearTimeout(this.isScrolling)
    this.isScrolling = setTimeout(this.onScroll, 0)
  }

  render() {
    const { menu } = this.props
    let { offCanvas, scrolled, hidden } = this.state

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: "active",
    }

    let className = "header"
    if (scrolled) className += " header--scroll"
    if (hidden) className += " header--hidden"

    return (
      <>
        <header className={className}>
          <div className="header__inner">
            <Link to="/" title="Systema" className="header__logo" {...props}>
              <Logo />
            </Link>
            <nav className="header__nav">
              <ul>
                {menu?.menuItems?.nodes?.map(
                  (el, i) =>
                    el.parentId === null && (
                      <li key={i}>
                        <Link to={el.path}>{el.label}</Link>

                        {el.childItems?.nodes?.length > 0 && (
                          <div className="header__nav-child">
                            {el.childItems.nodes.map((ce, j) => (
                              <React.Fragment key={j}>
                                <h6 key={`h${j}`}>
                                  <Link to={ce.path}>{ce.label}</Link>
                                </h6>
                                {ce.childItems?.nodes?.map((sc, k) => (
                                  <p key={`p${k}`}>
                                    <Link to={sc.path}>{sc.label}</Link>
                                  </p>
                                ))}
                              </React.Fragment>
                            ))}
                          </div>
                        )}
                      </li>
                    )
                )}

                <li>
                  {/* <button
                    type="button"
                    className="btn"
                    onClick={this.toggleModal}
                  >
                    Request Demo
                  </button> */}
                  <a 
                    href="/request-demo/"
                    className="btn"
                  >
                    Request Demo
                  </a>
                </li>
              </ul>
            </nav>
            <button
              onClick={this._toggleOffCanvas}
              className={`header__hamburger ${
                offCanvas ? "header__hamburger--active" : ""
              } `}
            >
              <span className="lines"></span>
            </button>
          </div>
        </header>
        <div className={`off-canvas ${offCanvas && "active"}`}>
          <div className="off-canvas__inner">
            <button
              type="button"
              className="form-modal__close"
              onClick={this._toggleOffCanvas}
            >
              <Plus fill={"black"} />
            </button>
            <nav className="off-canvas__nav">
              <ul>
                {menu?.menuItems?.nodes?.map((el, i) => {
                  return (
                    (el.parentId === null ||
                      el.parentId === "cG9zdDo2MzY=") && (
                      <li key={i} className={el.parentId ? "child-item" : ""}>
                        {el.label === "Resources" && (
                          <span>{el.label} (Coming soon)</span>
                        )}
                        {el.label !== "Resources" && (
                          <Link to={el.path} {...props}>
                            {el.label}
                          </Link>
                        )}
                      </li>
                    )
                  )
                })}
                <li>
                  {/* <button
                    type="button"
                    className="btn"
                    onClick={this.toggleModal}
                  >
                    Request Demo
                  </button> */}
                  <a 
                    href="/request-demo/"
                    className="btn"
                  >
                    Request Demo
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <button
          className={`scroll-top ${scrolled && "scroll-top--flip"}`}
          onClick={() =>
            window && window.scroll({ top: 0, left: 0, behavior: "smooth" })
          }
        >
          <TopArrow fill={`rgba(0, 0, 0, 0.1)`} />
        </button>
      </>
    )
  }
}

export default Header
