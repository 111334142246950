import React, { Component } from 'react'
//import addToMailchimp from 'gatsby-plugin-mailchimp'

import { Arrow } from './icons'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class NewsletterForm extends Component {

  state = {
    email: '',
    result: {}
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  // 2. via `async/await`
  handleSubmit = async (e) => {
    e.preventDefault();

    this.setState({ result: 'success' })
    const data = encode({ email: this.state.email, type: 'newsletter' })
    await fetch("https://hooks.zapier.com/hooks/catch/2181815/brir4y8/?"+data)

    // const result = await addToMailchimp(this.state.email, {
    //   FNAME: this.state.firstname,
    //   LNAME: this.state.lastname,
    // })
    // I recommend setting `result` to React state
    // but you can do whatever you want
    // this.setState({
    //   result: result.result,
    //   msg: result.msg
    // })
  }

  render() {
    let props = {
      ref: 'form',
      name: this.props.name,
      className: 'newsletter',
      onSubmit: this.handleSubmit,
      'data-netlify': 'true',
      'data-netlify-honeypot': 'bot-field',
    }

    if (this.state.result === 'success')
      return (
        <p className='contact__success'>
          Thanks for subscribing.
        </p>
      )

    return (
      <form {...props}>
        <div className='newsletter__row'>
          <input
            type='email'
            name='email'
            placeholder='Your email'
            onChange={this.handleChange}
            required
          />
          <button type='submit' className='btn'>
            <span>{ this.props.buttonText }</span>
            <Arrow fill={`#263238`} />
          </button>
        </div>
      </form>
    )
  }
}

NewsletterForm.defaultProps = {
  name: 'newsletter',
  buttonText: 'Subscribe',
}

export default NewsletterForm
